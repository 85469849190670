import React from 'react'

export const AnexoSection = ({children, title}:{children:React.ReactNode; title?:string;}) => {
  return (
    <div className='border-2 rounded-lg p-6 bg-slate-50 w-full'>
        <p className='mb-8 font-bold text-3xl'>{title}</p>
        {children}
    </div>
  )
}
export const AnexoLine = ({children}:{children:React.ReactNode;}) => {
  return (
    <div className='flex flex-col gap-2 w-full md:flex-row'>
        {children}
    </div>
  )
}

