'use client'

import React from 'react'
import Logo from '@/components/Logo'
import { AnexoLine } from '@/components/Anexos/AnexosLayout'
import AntInput from '@/components/AntInputs/AntInput'
import { Button, Form } from 'antd'
import type { FormProps } from 'antd';
import { useState,  } from 'react'
import { signIn, signInEmail } from './login'
import { Alert } from 'antd';
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/navigation'




const page = () => {

    // const defaultUrl = process.env.VERCEL_URL
    // ? `https://${process.env.VERCEL_URL}`
    // : "http://localhost:3000";

    // console.log('defaultUrl: ', defaultUrl);

    const searchParams = useSearchParams()
    const message = searchParams.get('message')
    const error = searchParams.get('error')
    const router = useRouter();

    const [form] = Form.useForm();


    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<string | null>(error ? error : null);
    const [success, setSuccess] = useState<string | null>(null);
    const [tipoInicio, setTipoInicio] = useState('password')

    // console.log('message', message);
    console.log('error', error);

    const onFinish: FormProps<RegisterValues>['onFinish'] = async (values) => {
        setIsLoading(true);
        setErrors(null);
        setSuccess(null);
        // console.log('Success:', values);
        console.log('Se pone el estado de carga y los errores en null')
        let result = tipoInicio === 'password' ? await signIn(values) : await signInEmail(values);

        console.log('la variable de result se carga')

        if (result.success) {
            if(tipoInicio == 'password'){
                router.push("/scala");
            } else {
                setIsLoading(false)
                setSuccess('Revisa tu correo. Te hemos enviado un enlace para que inicies sesión');
                console.log('result: ', result);
            }
        } else if (result.error) {

            if (result.error = '"Email not confirmed"') {
                setErrors('Verifica tu email para iniciar sesión');
            }

            if (result.error = '"Invalid login credentials"') {
                setErrors('Email o contraseña incorrectos');
            }
            console.log('error: ', result.error);
            setIsLoading(false)
        }


    };

    type RegisterValues = {
        email: string;
        password: string;
    };

    const cambiarInicio = (tipo: string) => {
        setTipoInicio(tipo)
        setErrors(null)
        setSuccess(null)
        form.resetFields(['password']) 
    }



    // useEffect(() => {
    //     setErrors(errorMessage)      
    // }, [])


    return (
        <div className='w-full h-full min-h-dvh max-h-dvh bg-slate-200 overflow-hidden flex justify-center items-center gap-4 px-6'>
            <div className='p-6 bg-white flex flex-col gap-8 w-full max-w-sm'>
                <div className='flex justify-center'>
                    <Logo />
                </div>
                <Form
                    name='login'
                    layout='vertical'
                    style={{ display: 'flex', flexDirection: 'column', gap: '0', alignItems: 'center' }}
                    onFinish={onFinish}
                    form={form}
                // labelCol={{span:5}}
                // labelAlign='left'
                // labelCol={{span: 6}}
                // wrapperCol={{span: 5}}
                >
                    <AnexoLine>
                        <AntInput type='email' name='email' title='Email' required={true} formName='acto' hideFeedback />
                    </AnexoLine>
                    {tipoInicio == 'password' &&
                        <AnexoLine>
                            <AntInput type='password' name='password' title='Contraseña' required={true} formName='acto' hideFeedback id='login_password' />
                        </AnexoLine>
                    }

                    {errors && <Alert message={errors} type="error" showIcon className='w-full' />}
                    {success && <Alert message={success} type="success" showIcon className='w-full' />}
                    <div className='w-full mt-6 flex flex-col gap-4'>
                        <Form.Item className='w-full' style={{ marginBottom: '0' }}>
                            <Button type="primary" htmlType="submit" block size='large' loading={isLoading}>
                                {/* <Button type="primary" htmlType="submit" block size='large'> */}
                                {isLoading ? "Iniciando Sesión" : "Iniciar Sesión"}
                            </Button>
                        </Form.Item>
                        {tipoInicio == 'password' &&
                            <Button type='text' block size='large' style={{ color: '#167716' }} onClick={() => cambiarInicio('email')}> Iniciar Sesión con Email</Button>
                        }
                        {tipoInicio == 'email' &&
                            <Button type='text' block size='large' style={{ color: '#167716' }} onClick={() => cambiarInicio('password')}> Iniciar Sesión con Contraseña</Button>
                        }
                    </div>

                </Form>
            </div>
        </div>
    )
}

export default page